/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
import { useEpiserver } from "@episerver/spa-core";
import SkeletonLayout from "./SkeletonLayout";
export const Loader = props => {
    const ctx = useEpiserver();
    const timeout = props.timeout || ctx.config().spinnerTimeout || 0;
    const [isVisible, setIsVisible] = useState(timeout === 0);
    if (ctx.config().enableSpinner !== true)
        return null;
    useEffect(() => {
        if (timeout === 0)
            return;
        setIsVisible(false);
        const timeoutHandle = setTimeout(() => {
            setIsVisible(true);
        }, timeout);
        return () => {
            clearTimeout(timeoutHandle);
        };
    }, []);
    if (isVisible) {
        return React.createElement(SkeletonLayout, null);
    }
    return null;
};
export default Loader;
